import axios from "axios";
export const axios_instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 1000000,
});

export async function authorizeMe(token) {
    axios_instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export async function me() {
    return new Promise((resolve, reject) => {
        axios_instance.get('v1/user').then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function signin(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/auth/login', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function signup(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/auth/register', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function forgotpassword(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/auth/forgot-password', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function newFeedback(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/helps', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function resetpassword(data, token) {
    return new Promise((resolve, reject) => {
        axios_instance.post(`v1/auth/reset-password?token=${token}`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function googleAuth(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/auth/google', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function updateCurrentUser(data) {
    return new Promise((resolve, reject) => {
        axios_instance.patch('v1/user', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


// question
export async function getQuestionAnswerList(page, limit) {
    let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc`
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/question-answer${query}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
export async function getQuestionAnswerByID(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/question-answer/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function updateQuestionAnswer(id, data) {
    return new Promise((resolve, reject) => {
        axios_instance.patch(`v1/question-answer/${id}`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
export async function deleteQuestion(id, data) {
    return new Promise((resolve, reject) => {
        axios_instance.delete(`v1/question-answer/${id}`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
export async function addQuestionAnswer(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post(`v1/question-answer`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
// interview
export async function getInterviewList(page, limit, filterName) {
    let query = `?page=${page}&limit=${limit}&sortBy=createdAt:desc`
    let withsearchquery = `?page=${page}&limit=${limit}&sortBy=createdAt:desc&name=${filterName}`

    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/interview${filterName ? withsearchquery : query}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function deleteInterview(id) {
    return new Promise((resolve, reject) => {
        axios_instance.delete(`v1/interview/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function updateInterview(id, data) {
    return new Promise((resolve, reject) => {
        axios_instance.patch(`v1/interview/${id}`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};



export async function interviewVideoAnalysis(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview/analysis', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function createInterview(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function createNewInterview(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview/create', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function createInterviewVideo(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview-video', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function uploadInterviewVideo(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview-video/upload', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function uploadVideo(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/interview/video-upload', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


// video upload url
export async function getVideoUploadURL(data) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/interview/video-upload-url?type=${data?.type}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function getInterviewByID(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/interview/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function graphData(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post(`v1/interview/graph-data`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function getQuestionAnswerInterviewByID(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/question-answer/interview/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


export async function getVideosByInterviewID(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/interview-video/interview/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function getuploadingStatus(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/loading-status`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function getQuestionType() {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/question-type?limit=1000&page=1&type=active`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


// get question answer list by id 
// question
export async function getQuestionAnswerListById(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post(`v1/question-answer/by-question-type`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};


// admin apis

// get users
export async function getUsersList(page, limit, filterName) {
    let query = `?page=${page}&limit=${limit}`
    let withsearchquery = `?page=${page}&limit=${limit}&name=${filterName}`

    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/admin/users${filterName ? withsearchquery : query}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function getUserByID(id) {
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/admin/users/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function deleteUser(id) {
    return new Promise((resolve, reject) => {
        axios_instance.delete(`v1/admin/users/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function adminSignup(data) {
    return new Promise((resolve, reject) => {
        axios_instance.post('v1/admin/users', data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
export async function adminUserUpdate(id, data) {
    return new Promise((resolve, reject) => {
        axios_instance.patch(`v1/admin/users/${id}`, data).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};



// question answer video
export async function getQuestionAnswerVideoList(page, limit, filter) {
    let query = `?page=${page}&limit=${limit}`
    if (filter?.type) query = query + `&type=${filter?.type}`
    return new Promise((resolve, reject) => {
        axios_instance.get(`v1/interview-video${query}&sortBy=createdAt:desc`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};

export async function deleteQuestionAnswerVideo(id) {
    return new Promise((resolve, reject) => {
        axios_instance.delete(`v1/interview-video/${id}`).then(function (response) {
            resolve(response);
        }).catch(function (error) { reject(error); })
    })
};
